import React from 'react';

import { TenantGlobalBlogPageData, TenantBlogPages } from '@ha/contentful';

import { useRequiredHermes } from 'ha/modules/Hermes';
import { LoadableNotFoundPage } from 'ha/pages/NotFound';

import { Routes } from '../routes';

const Root: React.FC = () => {
  const {
    data: { blogPage },
  } = useRequiredHermes<TenantGlobalBlogPageData>();

  if (Object.values(TenantBlogPages).includes(blogPage)) {
    const Component = Routes[blogPage];
    return <Component />;
  }

  return <LoadableNotFoundPage />;
};

export { Root };
